$border-color: #383838;
$background1: #202020;
$background2: #181818;

$dark-white: #e3e3e3;

body {
    color: white;
}

#loader {
    background-color: $background1;

    .spinner {
        background-color: white;
    }
}

.sidebar {
    background-color: $background1;

    .sidebar-logo {
        border-color: $border-color;

        a .logo-text {
            color: $dark-white;
        }
    }

    .sidebar-menu {
        border-color: $border-color;

        .nav-item {
            &.active {
                background: rgba(255, 255, 255, 0.1);
            }

            &:hover {
                background: rgba(255, 255, 255, 0.1);
            }

            &:active {
                background: rgba(255, 255, 255, 0.15);
            }
        }

        li {
            a {
                color: $dark-white;
            }
        }
    }
}

.header {
    background-color: $background1;
    border-color: $border-color;
}

.bgc-grey-100,
.bgcH-grey-100:hover {
    background-color: $background2 !important;
}

.bgc-white,
.bgcH-white {
    background-color: #303030 !important;
}

.c-grey-600,
.c-grey-900,
.c-grey-700,
.c-grey-700:hover {
    color: $dark-white !important;
}

.border-bottom,
.border-top,
.border-right,
.border-left,
.bd,
.bgc-white,
.bgcH-white:hover {
    border-color: $border-color !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    background-color: #303030 !important;
}
