$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ebebeb !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #999 !default;
$gray-700: #444 !default;
$gray-800: #303030 !default;
$gray-900: #222 !default;
$black: #000 !default;

$blue: #375a7f !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74c3c !default;
$orange: #fd7e14 !default;
$yellow: #f39c12 !default;
$green: #00bc8c !default;
$teal: #20c997 !default;
$cyan: #3498db !default;

$primary: $blue !default;
$secondary: $gray-500 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-600 !default;
$dark: $gray-800 !default;

$yiq-contrasted-threshold: 175 !default;

// Body

$body-bg: $gray-900 !default;
$body-color: $white !default;

// Links

$link-color: $success !default;

// Tables

$table-accent-bg: $gray-800 !default;

$table-border-color: $gray-700 !default;

// Forms

$input-border-color: $body-bg !default;

$input-group-addon-color: $gray-500 !default;
$input-group-addon-bg: $gray-700 !default;

$custom-file-color: $gray-500 !default;
$custom-file-border-color: $body-bg !default;

$custom-select-bg: $black !default;
$custom-select-color: $gray-300 !default;
$custom-select-border-color: $gray-600 !default;
$custom-select-indicator-color: $gray-600 !default;

$custom-control-indicator-bg: $black !default;
$custom-control-indicator-border-color: $gray-600 !default;

// Dropdowns

$dropdown-bg: $gray-900 !default;
$dropdown-border-color: $gray-700 !default;
$dropdown-divider-bg: $gray-700 !default;

$dropdown-link-color: $white !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: rgba(255, 255, 255, 0.1) !default;
$dropdown-link-active-bg: rgba(255, 255, 255, 0.1) !default;

// Navs

$nav-link-disabled-color: $gray-500 !default;

$nav-tabs-border-color: $gray-700 !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent !default;
$nav-tabs-link-active-color: $white !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color transparent !default;

// Navbar

$navbar-dark-color: rgba($white, 0.6) !default;
$navbar-dark-hover-color: $white !default;

$navbar-light-color: $white !default;
$navbar-light-hover-color: $success !default;
$navbar-light-active-color: $white !default;
$navbar-light-disabled-color: rgba($white, 0.3) !default;
$navbar-light-toggler-border-color: rgba($white, 0.1) !default;

// Pagination

$pagination-color: $primary !default;
$pagination-bg: $gray-900 !default;
$pagination-border-color: $gray-700 !default;

$pagination-hover-color: $primary !default;
$pagination-hover-bg: $gray-800 !default;
$pagination-hover-border-color: $gray-700 !default;

$pagination-disabled-bg: $gray-900 !default;
$pagination-disabled-border-color: $gray-700 !default;

// Jumbotron

$jumbotron-bg: $gray-800 !default;

// Cards

$card-cap-bg: $gray-700 !default;
$card-bg: $gray-800 !default;
$card-border-color: $gray-700 !default;

// Popovers

$popover-bg: $gray-800 !default;
$popover-header-bg: $gray-700 !default;

// Modals

$modal-content-bg: $gray-800 !default;
$modal-content-border-color: $gray-700 !default;
$modal-header-border-color: $gray-700 !default;

// Progress bars

$progress-bg: $gray-700 !default;

// List group

$list-group-bg: $gray-800 !default;
$list-group-border-color: $gray-700 !default;
$list-group-hover-bg: $gray-700 !default;
$list-group-active-bg: rgba(0, 0, 0, 0.05) !default;

// Breadcrumbs

$breadcrumb-bg: $gray-700 !default;

// Close

$close-color: $white !default;
$close-text-shadow: none !default;

// Code

$pre-color: inherit !default;

@import '~bootstrap/scss/bootstrap';

// Variables ===================================================================

// $web-font-path: 'https://fonts.googleapis.com/css?family=Lato:400,700,400italic' !default;
// @import url($web-font-path);

// Navbar ======================================================================

.bg-primary {
    .navbar-nav .active > .nav-link {
        color: $success !important;
    }
}

.bg-light {
    &.navbar {
        background-color: $success !important;
    }

    &.navbar-light .navbar-nav {
        .nav-link:focus,
        .nav-link:hover,
        .active > .nav-link {
            color: $primary !important;
        }
    }
}

// Buttons =====================================================================

// Typography ==================================================================

.blockquote {
    &-footer {
        color: $gray-600;
    }
}

// Tables ======================================================================

.table {
    &-primary {
        &,
        > th,
        > td {
            background-color: $primary;
        }
    }

    &-secondary {
        &,
        > th,
        > td {
            background-color: $secondary;
        }
    }

    &-light {
        &,
        > th,
        > td {
            background-color: $light;
        }
    }

    &-dark {
        &,
        > th,
        > td {
            background-color: $dark;
        }
    }

    &-success {
        &,
        > th,
        > td {
            background-color: $success;
        }
    }

    &-info {
        &,
        > th,
        > td {
            background-color: $info;
        }
    }

    &-danger {
        &,
        > th,
        > td {
            background-color: $danger;
        }
    }

    &-warning {
        &,
        > th,
        > td {
            background-color: $warning;
        }
    }

    &-active {
        &,
        > th,
        > td {
            background-color: $table-active-bg;
        }
    }

    &-hover {
        .table-primary:hover {
            &,
            > th,
            > td {
                background-color: darken($primary, 5%);
            }
        }

        .table-secondary:hover {
            &,
            > th,
            > td {
                background-color: darken($secondary, 5%);
            }
        }

        .table-light:hover {
            &,
            > th,
            > td {
                background-color: darken($light, 5%);
            }
        }

        .table-dark:hover {
            &,
            > th,
            > td {
                background-color: darken($dark, 5%);
            }
        }

        .table-success:hover {
            &,
            > th,
            > td {
                background-color: darken($success, 5%);
            }
        }

        .table-info:hover {
            &,
            > th,
            > td {
                background-color: darken($info, 5%);
            }
        }

        .table-danger:hover {
            &,
            > th,
            > td {
                background-color: darken($danger, 5%);
            }
        }

        .table-warning:hover {
            &,
            > th,
            > td {
                background-color: darken($warning, 5%);
            }
        }

        .table-active:hover {
            &,
            > th,
            > td {
                background-color: $table-active-bg;
            }
        }
    }
}

// Forms =======================================================================

.input-group-addon {
    color: #fff;
}

// Navs ========================================================================

.nav-tabs,
.nav-pills {
    .nav-link,
    .nav-link.active,
    .nav-link.active:focus,
    .nav-link.active:hover,
    .nav-item.open .nav-link,
    .nav-item.open .nav-link:focus,
    .nav-item.open .nav-link:hover {
        color: #fff;
    }
}

.breadcrumb a {
    color: #fff;
}

.pagination {
    a:hover {
        text-decoration: none;
    }
}

// Indicators ==================================================================

.close {
    opacity: 0.4;

    &:hover,
    &:focus {
        opacity: 1;
    }
}

.alert {
    border: none;
    color: $white;

    a,
    .alert-link {
        color: #fff;
        text-decoration: underline;
    }

    @each $color, $value in $theme-colors {
        &-#{$color} {
            @if $enable-gradients {
                background: $value linear-gradient(180deg, mix($white, $value, 15%), $value) repeat-x;
            } @else {
                background-color: $value;
            }
        }
    }
}

// Progress bars ===============================================================

// Containers ==================================================================

.list-group-item-action {
    color: #fff;

    &:hover,
    &:focus {
        background-color: $gray-700;
        color: #fff;
    }

    .list-group-item-heading {
        color: #fff;
    }
}

.form-control,
.form-control:active,
.form-control:focus {
    background-color: $black;
    color: $gray-300;
    border-color: $gray-600;
}

.form-control:-webkit-scrollbar {
    width: 8px;
    height: 3px;
}
.form-control:-webkit-scrollbar-track {
    background-color: #646464;
}
.form-control:-webkit-scrollbar-track-piece {
    background-color: #000;
}
.form-control:-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #666;
    border-radius: 3px;
}
.form-control:-webkit-scrollbar-corner {
    background-color: #646464;
}
.form-control:-webkit-resizer {
    background-color: #666;
}

.form-control:disabled,
.dark-theme .form-control[readonly] {
    background-color: #0003;
}

// .list-group-item-success {

// }

// .list-group-item-warning {

// }

// .list-group-item-secondary {

// }
