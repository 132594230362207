@import 'spec/settings/index';
@import 'spec/tools/index';

.light-theme {
    @import 'light';

    @import 'spec/index';
    @import 'custom';
}

.dark-theme {
    @import 'dark';
}

.light-theme,
.dark-theme {
    @import 'spec/index';
    @import 'custom';
}

.light-theme {
    @import 'light-custom';
}

.dark-theme {
    @import 'dark-custom';
}

@import 'vendor/index';
