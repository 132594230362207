// ---------------------------------------------------------
// @TOC
// ---------------------------------------------------------

// + @Variables
// + @Padding (0 > 4 Step 1)
// + @Padding (5 > 35 Step 5)
// + @Padding (40 > 160 Step 10)
// + @Padding Auto

// ---------------------------------------------------------
// @Variables
// ---------------------------------------------------------

$responsive: true;

// ---------------------------------------------------------
// @Padding (0 > 4 Step 1)
// ---------------------------------------------------------

@for $i from 0 through 4 {
    .p-#{$i} {
        padding: #{$i}px !important;
    }
    .pT-#{$i} {
        padding-top: #{$i}px !important;
    }
    .pR-#{$i} {
        padding-right: #{$i}px !important;
    }
    .pB-#{$i} {
        padding-bottom: #{$i}px !important;
    }
    .pL-#{$i} {
        padding-left: #{$i}px !important;
    }

    .pY-#{$i} {
        padding-top: #{$i}px !important;
        padding-bottom: #{$i}px !important;
    }

    .pX-#{$i} {
        padding-left: #{$i}px !important;
        padding-right: #{$i}px !important;
    }

    @if ($responsive == true) {
        @include generateResponsive() {
            .p-#{$i}\@#{$breakpointAlias} {
                padding: #{$i}px !important;
            }
            .pT-#{$i}\@#{$breakpointAlias} {
                padding-top: #{$i}px !important;
            }
            .pR-#{$i}\@#{$breakpointAlias} {
                padding-right: #{$i}px !important;
            }
            .pB-#{$i}\@#{$breakpointAlias} {
                padding-bottom: #{$i}px !important;
            }
            .pL-#{$i}\@#{$breakpointAlias} {
                padding-left: #{$i}px !important;
            }

            .pY-#{$i}\@#{$breakpointAlias} {
                padding-top: #{$i}px !important;
                padding-bottom: #{$i}px !important;
            }

            .pX-#{$i}\@#{$breakpointAlias} {
                padding-left: #{$i}px !important;
                padding-right: #{$i}px !important;
            }
        }
    }
}

// ---------------------------------------------------------
// @Padding (5 > 35 Step 5)
// ---------------------------------------------------------

@for $i from 5 through 35 {
    @if $i % 5 == 0 {
        .p-#{$i} {
            padding: #{$i}px !important;
        }
        .pT-#{$i} {
            padding-top: #{$i}px !important;
        }
        .pR-#{$i} {
            padding-right: #{$i}px !important;
        }
        .pB-#{$i} {
            padding-bottom: #{$i}px !important;
        }
        .pL-#{$i} {
            padding-left: #{$i}px !important;
        }

        .pY-#{$i} {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }

        .pX-#{$i} {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }

        @if ($responsive == true) {
            @include generateResponsive() {
                .p-#{$i}\@#{$breakpointAlias} {
                    padding: #{$i}px !important;
                }
                .pT-#{$i}\@#{$breakpointAlias} {
                    padding-top: #{$i}px !important;
                }
                .pR-#{$i}\@#{$breakpointAlias} {
                    padding-right: #{$i}px !important;
                }
                .pB-#{$i}\@#{$breakpointAlias} {
                    padding-bottom: #{$i}px !important;
                }
                .pL-#{$i}\@#{$breakpointAlias} {
                    padding-left: #{$i}px !important;
                }

                .pY-#{$i}\@#{$breakpointAlias} {
                    padding-top: #{$i}px !important;
                    padding-bottom: #{$i}px !important;
                }

                .pX-#{$i}\@#{$breakpointAlias} {
                    padding-left: #{$i}px !important;
                    padding-right: #{$i}px !important;
                }
            }
        }
    }
}

// ---------------------------------------------------------
// @Padding (40 > 160 Step 10)
// ---------------------------------------------------------

@for $i from 40 through 160 {
    @if $i % 10 == 0 {
        .p-#{$i} {
            padding: #{$i}px !important;
        }
        .pT-#{$i} {
            padding-top: #{$i}px !important;
        }
        .pR-#{$i} {
            padding-right: #{$i}px !important;
        }
        .pB-#{$i} {
            padding-bottom: #{$i}px !important;
        }
        .pL-#{$i} {
            padding-left: #{$i}px !important;
        }

        .pY-#{$i} {
            padding-top: #{$i}px !important;
            padding-bottom: #{$i}px !important;
        }

        .pX-#{$i} {
            padding-left: #{$i}px !important;
            padding-right: #{$i}px !important;
        }

        @if ($responsive == true) {
            @include generateResponsive() {
                .p-#{$i}\@#{$breakpointAlias} {
                    padding: #{$i}px !important;
                }
                .pT-#{$i}\@#{$breakpointAlias} {
                    padding-top: #{$i}px !important;
                }
                .pR-#{$i}\@#{$breakpointAlias} {
                    padding-right: #{$i}px !important;
                }
                .pB-#{$i}\@#{$breakpointAlias} {
                    padding-bottom: #{$i}px !important;
                }
                .pL-#{$i}\@#{$breakpointAlias} {
                    padding-left: #{$i}px !important;
                }

                .pY-#{$i}\@#{$breakpointAlias} {
                    padding-top: #{$i}px !important;
                    padding-bottom: #{$i}px !important;
                }

                .pX-#{$i}\@#{$breakpointAlias} {
                    padding-left: #{$i}px !important;
                    padding-right: #{$i}px !important;
                }
            }
        }
    }
}
