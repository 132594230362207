// ---------------------------------------------------------
// @TOC
// ---------------------------------------------------------

// + @Variables
// + @Centering

// ---------------------------------------------------------
// @Variables
// ---------------------------------------------------------

$responsive: true;

// ---------------------------------------------------------
// @Centering
// ---------------------------------------------------------

.centerY {
    top: 50%;
    transform: translateY(-50%);
}

.centerX {
    left: 50%;
    transform: translateX(-50%);
}

.centerXY {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@if ($responsive == true) {
    @include generateResponsive() {
        .centerY\@#{$breakpointAlias} {
            top: 50%;
            transform: translateY(-50%);
        }

        .centerX\@#{$breakpointAlias} {
            left: 50%;
            transform: translateX(-50%);
        }

        .centerXY\@#{$breakpointAlias} {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
