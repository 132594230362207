.sidebar {
    .sidebar-menu {
        .nav-item {
            &.active {
                background: rgba(0, 0, 0, 0.05);
            }

            &:hover {
                background: rgba(0, 0, 0, 0.05);
            }

            &:active {
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }
}
