.react-datepicker-popper.react-datepicker-popper.react-datepicker-popper {
    z-index: 100;

    .react-datepicker {
        border-color: $grey-400;
    }

    .react-datepicker__triangle {
        border-bottom-color: $grey-100;
    }

    .react-datepicker__triangle:before,
    .react-datepicker__header {
        border-bottom-color: $grey-400;
    }

    .react-datepicker__header {
        background-color: $grey-100;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header,
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name,
    .react-datepicker__year-read-view--selected-year,
    .react-datepicker-time__caption {
        color: $grey-700;
    }

    .react-datepicker__day:hover,
    .react-datepicker__day--keyboard-selected {
        background-color: $grey-100;
    }

    .react-datepicker__day--outside-month {
        color: $grey-500;
    }

    .react-datepicker__day--selected.react-datepicker__day--selected {
        color: $default-white;
        background-color: $md-blue-500;
    }

    .react-datepicker__input-time-container {
        display: flex;
        align-items: center;
        margin: 0;
        float: none;
        padding: 15px;
    }
    .react-datepicker-time__input-container {
        flex-grow: 1;
    }
    div.react-datepicker-time__input {
        display: block;
    }
    // style time input as .form-control-sm
    input.react-datepicker-time__input {
        margin: 0;
        appearance: none;
        display: block;
        width: 100%;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: $default-white;
        background-clip: padding-box;
        border: 1px solid $grey-400;
        height: calc(1.5em + 0.5rem + 2px);
        padding: 0.25rem 0.5rem;
        font-size: 0.76562rem;
        border-radius: 0.2rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}

.react-datepicker-wrapper.react-datepicker-wrapper {
    width: 100%;

    .form-control.is-invalid,
    .form-control.is-valid {
        background-position-x: right 1.75rem;
        padding-right: 3rem;
    }

    .react-datepicker__close-icon {
        padding: 0 10px;

        &::after {
            font-size: 20px;
            background-color: transparent;
            color: $theme-dark;
        }
    }
}
