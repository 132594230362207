.checkbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 17px;
    margin-bottom: 0;
}

.checkbox label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: -12px;
    border: 1px solid $grey-300;
    border-radius: 3px;
    background-color: $md-white;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    text-align: center;
    font-size: 10px !important;
    line-height: 17px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-left: -12px;
    color: $grey-500;
}

.checkbox input[type='checkbox'] {
    opacity: 0;
    z-index: 1;
}

.checkbox input[type='checkbox']:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.checkbox input[type='checkbox']:checked + label::after {
    font-family: 'FontAwesome';
    content: '\f00c';
    font-size: 13px;
}

.checkbox input[type='checkbox']:disabled + label {
    opacity: 0.65;
}

.checkbox input[type='checkbox']:disabled + label::before {
    background-color: $grey-400;
    cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
    border-radius: 50%;
}

.checkbox.checkbox-inline {
    margin-top: 0;
}

.checkbox-primary input[type='checkbox']:checked + label::before {
    background-color: $default-primary;
    border-color: $default-primary;
}

.checkbox-primary input[type='checkbox']:checked + label::after {
    color: #fff;
}

.checkbox-danger input[type='checkbox']:checked + label::before {
    background-color: $default-danger;
    border-color: $default-danger;
}

.checkbox-danger input[type='checkbox']:checked + label::after {
    color: $md-white;
}

.checkbox-info input[type='checkbox']:checked + label::before {
    background-color: $default-info;
    border-color: $default-info;
}

.checkbox-info input[type='checkbox']:checked + label::after {
    color: $md-white;
}

.checkbox-warning input[type='checkbox']:checked + label::before {
    background-color: $default-warning;
    border-color: $default-warning;
}

.checkbox-warning input[type='checkbox']:checked + label::after {
    color: $md-white;
}

.checkbox-success input[type='checkbox']:checked + label::before {
    background-color: $default-success;
    border-color: $default-success;
}

.checkbox-success input[type='checkbox']:checked + label::after {
    color: $md-white;
}

.radio {
    padding-left: 20px;
}

.radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
}

.radio label::before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}

.radio label::after {
    display: inline-block;
    position: absolute;
    content: ' ';
    width: 11px;
    height: 11px;
    left: 3px;
    top: 3px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #555555;
    -webkit-transform: scale(0, 0);
    -ms-transform: scale(0, 0);
    -o-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -moz-transition: -moz-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    -o-transition: -o-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}

.radio input[type='radio'] {
    opacity: 0;
    z-index: 1;
}

.radio input[type='radio']:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.radio input[type='radio']:checked + label::after {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
}

.radio input[type='radio']:disabled + label {
    opacity: 0.65;
}

.radio input[type='radio']:disabled + label::before {
    cursor: not-allowed;
}

.radio.radio-inline {
    margin-top: 0;
}

.radio-primary input[type='radio'] + label::after {
    background-color: #428bca;
}

.radio-primary input[type='radio']:checked + label::before {
    border-color: #428bca;
}

.radio-primary input[type='radio']:checked + label::after {
    background-color: #428bca;
}

.radio-danger input[type='radio'] + label::after {
    background-color: #d9534f;
}

.radio-danger input[type='radio']:checked + label::before {
    border-color: #d9534f;
}

.radio-danger input[type='radio']:checked + label::after {
    background-color: #d9534f;
}

.radio-info input[type='radio'] + label::after {
    background-color: #5bc0de;
}

.radio-info input[type='radio']:checked + label::before {
    border-color: #5bc0de;
}

.radio-info input[type='radio']:checked + label::after {
    background-color: #5bc0de;
}

.radio-warning input[type='radio'] + label::after {
    background-color: #f0ad4e;
}

.radio-warning input[type='radio']:checked + label::before {
    border-color: #f0ad4e;
}

.radio-warning input[type='radio']:checked + label::after {
    background-color: #f0ad4e;
}

.radio-success input[type='radio'] + label::after {
    background-color: #5cb85c;
}

.radio-success input[type='radio']:checked + label::before {
    border-color: #5cb85c;
}

.radio-success input[type='radio']:checked + label::after {
    background-color: #5cb85c;
}
