// ---------------------------------------------------------
// @TOC
// ---------------------------------------------------------

// + @Variables
// + @Position (0 > 4 Step 1)
// + @Position (5 > 35 Step 5)
// + @Position (40 > 160 Step 10)

// ---------------------------------------------------------
// @Variables
// ---------------------------------------------------------

$responsive: true;

// ---------------------------------------------------------
// @Position (0 > 4 Step 1)
// ---------------------------------------------------------

@for $i from 0 through 4 {
    .t-#{$i} {
        top: #{$i}px;
    }
    .r-#{$i} {
        right: #{$i}px;
    }
    .b-#{$i} {
        bottom: #{$i}px;
    }
    .l-#{$i} {
        left: #{$i}px;
    }

    @if ($responsive == true) {
        @include generateResponsive() {
            .t-#{$i}\@#{$breakpointAlias} {
                top: #{$i}px;
            }
            .r-#{$i}\@#{$breakpointAlias} {
                right: #{$i}px;
            }
            .b-#{$i}\@#{$breakpointAlias} {
                bottom: #{$i}px;
            }
            .l-#{$i}\@#{$breakpointAlias} {
                left: #{$i}px;
            }
        }
    }
}

// ---------------------------------------------------------
// @Position (5 > 35 Step 5)
// ---------------------------------------------------------

@for $i from 5 through 35 {
    @if $i % 5 == 0 {
        .t-#{$i} {
            top: #{$i}px;
        }
        .r-#{$i} {
            right: #{$i}px;
        }
        .b-#{$i} {
            bottom: #{$i}px;
        }
        .l-#{$i} {
            left: #{$i}px;
        }

        @if ($responsive == true) {
            @include generateResponsive() {
                .t-#{$i}\@#{$breakpointAlias} {
                    top: #{$i}px;
                }
                .r-#{$i}\@#{$breakpointAlias} {
                    right: #{$i}px;
                }
                .b-#{$i}\@#{$breakpointAlias} {
                    bottom: #{$i}px;
                }
                .l-#{$i}\@#{$breakpointAlias} {
                    left: #{$i}px;
                }
            }
        }
    }
}

// ---------------------------------------------------------
// @Position (40 > 160 Step 10)
// ---------------------------------------------------------

@for $i from 40 through 160 {
    @if $i % 10 == 0 {
        .t-#{$i} {
            top: #{$i}px;
        }
        .r-#{$i} {
            right: #{$i}px;
        }
        .b-#{$i} {
            bottom: #{$i}px;
        }
        .l-#{$i} {
            left: #{$i}px;
        }

        @if ($responsive == true) {
            @include generateResponsive() {
                .t-#{$i}\@#{$breakpointAlias} {
                    top: #{$i}px;
                }
                .r-#{$i}\@#{$breakpointAlias} {
                    right: #{$i}px;
                }
                .b-#{$i}\@#{$breakpointAlias} {
                    bottom: #{$i}px;
                }
                .l-#{$i}\@#{$breakpointAlias} {
                    left: #{$i}px;
                }
            }
        }
    }
}

// ---------------------------------------------------------
// @Position (50%)
// ---------------------------------------------------------

.tl-50p {
    top: 50%;
    left: 50%;
}

.tr-50p {
    top: 50%;
    right: 50%;
}

.t-50p {
    top: 50%;
}
.r-50p {
    right: 50%;
}
.b-50p {
    bottom: 50%;
}
.l-50p {
    left: 50%;
}

@if ($responsive == true) {
    @include generateResponsive() {
        .tl-50p\@#{$breakpointAlias} {
            top: 50%;
            left: 50%;
        }

        .tr-50p\@#{$breakpointAlias} {
            top: 50%;
            right: 50%;
        }

        .t-50p\@#{$breakpointAlias} {
            top: 50%;
        }
        .r-50p\@#{$breakpointAlias} {
            right: 50%;
        }
        .b-50p\@#{$breakpointAlias} {
            bottom: 50%;
        }
        .l-50p\@#{$breakpointAlias} {
            left: 50%;
        }
    }
}
