// Variables

$primary-color: #2196f3;
$danger-color: #f44336;
$success-color: #4caf50;
$warning-color: #ffc107;

// User dropdown fix

.header .header-container .nav-right .dropdown-menu {
    top: initial !important;
    left: auto !important;
    right: 2px !important;
    transform: scale(0, 0) !important;
}

.header .header-container .nav-right .show .dropdown-menu {
    transform: scale(1, 1) !important;
}

.header .header-container .nav-left .dropdown-menu {
    top: initial !important;
    left: 0 !important;
    right: auto !important;
    transform: scale(0, 0) !important;
}

.header .header-container .nav-left .show .dropdown-menu {
    transform: scale(1, 1) !important;
}

// Icon buttons

.icon-button-container {
    display: flex;
    justify-content: flex-end;
    height: 28px;
    width: 40px;
    box-sizing: border-box;
}

.icon-button {
    text-decoration: none !important;
    color: $primary-color;
    padding: 0;
    position: relative;

    &.primary {
        color: $primary-color;
    }
    &.danger {
        color: $danger-color;
    }
    &.success {
        color: $success-color;
    }
}

.icon-button [class^='ti-'],
.icon-button [class*=' ti-'] {
    font-size: 25px;
    transition: 0.2s;
}

// Yellow color change

.btn-outline-warning,
.btn-warning {
    color: $warning-color;
    border-color: $warning-color;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.text-warning {
    color: $warning-color !important;
}

// Custom scrollbar

::-webkit-scrollbar {
    width: 8px;
    height: 3px;
}
::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}
::-webkit-scrollbar-track-piece {
    background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #555;
    border-radius: 3px;
}
::-webkit-scrollbar-corner {
    background-color: #f5f5f5;
}
::-webkit-resizer {
    background-color: #f5f5f5;
}

// Footer remove

.main-content {
    min-height: 100vh;
}

// Page loader

#loader {
    transition: all 0.3s ease-in-out;
    opacity: 1;
    visibility: visible;
    position: fixed;
    height: 100vh;
    width: 100%;
    background: #fff;
    z-index: 90000;
}

#loader.fadeOut {
    opacity: 0;
    visibility: hidden;
}

#loader .spinner {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

// No outline

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

// Helpers

.cursor-pointer {
    cursor: pointer;
}
// END Helpers
.sn-transparent-accordion {
    .card {
        &,
        .collapse,
        .card-header {
            background: none;
            border: none;
        }
    }
    .help-expandable-show-more {
        padding-top: 4px;
    }
    .help-expandable-icon {
        margin-top: -2px;
    }
}

.sn-help-icon {
    border-radius: 20px;
    border: 2px solid $blue;
    padding: 3px;
    font-weight: bold;
}
.sn-help-tooltip-icon {
    border-radius: 16px;
    border: 1px solid $blue;
    font-size: 10px;
    padding: 2px;
    border-width: 1px;
    max-height: 16px;
    display: inline-block;
}

// Cell styles
.cell-vertical-align {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cell-button-toolbar {
    height: 37px;
}

// Sticky footer
.items__sticky-footer {
    transition: all 0.2s ease;
    position: sticky;
}

.items__sticky-footer--visible {
    @extend .items__sticky-footer;
    height: 70px;
}

.items__sticky-footer--hidden {
    @extend .items__sticky-footer;
    height: 0;
    overflow: hidden;
}

.items__sticky-footer--position-bottom {
    @extend .items__sticky-footer;
    bottom: 0;
}

// Other
.ctr-grow-arrow {
    font-weight: bold;
    color: $success-color;
}

.ctr-down-arrow {
    font-weight: bold;
    color: $danger-color;
}

.ctr-not-changed {
    font-weight: bold;
    color: grey;
}

.suggestible-item-added-part {
    color: $success-color;
}

.suggestible-item-badge {
    text-transform: uppercase;
}

// fix Boostrap Form.Control.Feedback not showing up because of our CSS import specifics
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
    display: block;
}
