// ---------------------------------------------------------
// @TOC
// ---------------------------------------------------------

// + @Variables
// + @Base
// + @Gap (0 > 4 Step 1)
// + @Gap (5 > 35 Step 5)
// + @Gap (40 > 160 Step 10)

// ---------------------------------------------------------
// @Variables
// ---------------------------------------------------------

$responsive: true;

// ---------------------------------------------------------
// @Base
// ---------------------------------------------------------

[class*='gap'] {
    width: auto !important;
    overflow: hidden !important;
}

// ---------------------------------------------------------
// @Gap (0 > 4 Step 1)
// ---------------------------------------------------------

@for $i from 0 through 4 {
    .gapX-#{$i} {
        margin-left: #{($i / -2)}px !important;
        margin-right: #{($i / -2)}px !important;

        & > * {
            padding-left: #{($i / 2)}px !important;
            padding-right: #{($i / 2)}px !important;
        }
    }

    .gapY-#{$i} {
        margin-top: #{($i / -2)}px !important;
        margin-bottom: #{($i / -2)}px !important;

        & > * {
            padding-top: #{($i / 2)}px !important;
            padding-bottom: #{($i / 2)}px !important;
        }
    }

    .gap-#{$i} {
        margin: #{($i / -2)}px !important;

        & > * {
            padding: #{($i / 2)}px !important;
        }
    }

    @if ($responsive == true) {
        @include generateResponsive() {
            .gapX-#{$i}\@#{$breakpointAlias} {
                margin-left: #{($i / -2)}px !important;
                margin-right: #{($i / -2)}px !important;

                & > * {
                    padding-left: #{($i / 2)}px !important;
                    padding-right: #{($i / 2)}px !important;
                }
            }

            .gapY-#{$i}\@#{$breakpointAlias} {
                margin-top: #{($i / -2)}px !important;
                margin-bottom: #{($i / -2)}px !important;

                & > * {
                    padding-top: #{($i / 2)}px !important;
                    padding-bottom: #{($i / 2)}px !important;
                }
            }

            .gap-#{$i}\@#{$breakpointAlias} {
                margin: #{($i / -2)}px !important;

                & > * {
                    padding: #{($i / 2)}px !important;
                }
            }
        }
    }
}

// ---------------------------------------------------------
// @Gap (5 > 35 Step 5)
// ---------------------------------------------------------

@for $i from 5 through 35 {
    @if $i % 5 == 0 {
        .gapX-#{$i} {
            margin-left: #{($i / -2)}px !important;
            margin-right: #{($i / -2)}px !important;

            & > * {
                padding-left: #{($i / 2)}px !important;
                padding-right: #{($i / 2)}px !important;
            }
        }

        .gapY-#{$i} {
            margin-top: #{($i / -2)}px !important;
            margin-bottom: #{($i / -2)}px !important;

            & > * {
                padding-top: #{($i / 2)}px !important;
                padding-bottom: #{($i / 2)}px !important;
            }
        }

        .gap-#{$i} {
            margin: #{($i / -2)}px !important;

            & > * {
                padding: #{($i / 2)}px !important;
            }
        }

        @if ($responsive == true) {
            @include generateResponsive() {
                .gapX-#{$i}\@#{$breakpointAlias} {
                    margin-left: #{($i / -2)}px !important;
                    margin-right: #{($i / -2)}px !important;

                    & > * {
                        padding-left: #{($i / 2)}px !important;
                        padding-right: #{($i / 2)}px !important;
                    }
                }

                .gapY-#{$i}\@#{$breakpointAlias} {
                    margin-top: #{($i / -2)}px !important;
                    margin-bottom: #{($i / -2)}px !important;

                    & > * {
                        padding-top: #{($i / 2)}px !important;
                        padding-bottom: #{($i / 2)}px !important;
                    }
                }

                .gap-#{$i}\@#{$breakpointAlias} {
                    margin: #{($i / -2)}px !important;

                    & > * {
                        padding: #{($i / 2)}px !important;
                    }
                }
            }
        }
    }
}

// ---------------------------------------------------------
// @Gap (40 > 160 Step 10)
// ---------------------------------------------------------

@for $i from 40 through 160 {
    @if $i % 10 == 0 {
        .gapX-#{$i} {
            margin-left: #{($i / -2)}px !important;
            margin-right: #{($i / -2)}px !important;

            & > * {
                padding-left: #{($i / 2)}px !important;
                padding-right: #{($i / 2)}px !important;
            }
        }

        .gapY-#{$i} {
            margin-top: #{($i / -2)}px !important;
            margin-bottom: #{($i / -2)}px !important;

            & > * {
                padding-top: #{($i / 2)}px !important;
                padding-bottom: #{($i / 2)}px !important;
            }
        }

        .gap-#{$i} {
            margin: #{($i / -2)}px !important;

            & > * {
                padding: #{($i / 2)}px !important;
            }
        }

        @if ($responsive == true) {
            @include generateResponsive() {
                .gapX-#{$i}\@#{$breakpointAlias} {
                    margin-left: #{($i / -2)}px !important;
                    margin-right: #{($i / -2)}px !important;

                    & > * {
                        padding-left: #{($i / 2)}px !important;
                        padding-right: #{($i / 2)}px !important;
                    }
                }

                .gapY-#{$i}\@#{$breakpointAlias} {
                    margin-top: #{($i / -2)}px !important;
                    margin-bottom: #{($i / -2)}px !important;

                    & > * {
                        padding-top: #{($i / 2)}px !important;
                        padding-bottom: #{($i / 2)}px !important;
                    }
                }

                .gap-#{$i}\@#{$breakpointAlias} {
                    margin: #{($i / -2)}px !important;

                    & > * {
                        padding: #{($i / 2)}px !important;
                    }
                }
            }
        }
    }
}
